import React from "react"

export default function NotFoundPage() {
  return (
    <>
      <div className="py-4">
        <h1>Nothing here sorry.</h1>
        <hr />

        <p>
          We've made a lot of changes on this site so it might have been moved.
        </p>

        <p>
          <a href="/">Click here to find what you were looking for.</a>
        </p>
      </div>
    </>
  )
}
